import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase';
// redux
import { login, register, logout } from '../redux/slices/authJwt';
import axios from 'axios';

const saveUrl = process.env.REACT_APP_SAVE_URL + 'user';
const userUrl = process.env.REACT_APP_GET_URL + 'user';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth(method = 'firebase') {
  // Firebase Auth
  const firebase = useFirebase();
  const { auth, profile } = useSelector((state) => state.firebase);
  const [apiuser, setAPIUser] = useState({
    user_id: '',
    user_firstname: '',
    user_lastname: '',
    user_username: '',
    user_email: '',
    //user_address: '',
    //user_city: '',
    //user_postal: '',
    photoURL: '',
    user_province: '',
    user_current_sport: '',
    user_current_profile: '',
    user_public: false,
    profiles: [],
    roles: []
  });
  const [loginType, setLoginType] = useState('');

  // GET USER FROM API
  const getUser = useCallback(async () => {
    try {
      if (auth.uid && !apiuser.uid) {
        const response = await axios.get(userUrl + '?uid=' + auth.uid);
        //console.log('getting user');
        //console.log(response.data);
        setAPIUser(response.data);
      }
    } catch (err) {
      //
    }
  }, [auth.uid, apiuser.uid]);
  useEffect(() => {
    getUser();
  }, [getUser]);

  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  // JWT Auth
  if (method === 'jwt') {
    return {
      method: 'jwt',
      user,
      isLoading,
      isAuthenticated,

      login: ({ email, password }) =>
        dispatch(
          login({
            email,
            password
          })
        ),

      register: ({ email, password, firstName, lastName }) =>
        dispatch(
          register({
            email,
            password,
            firstName,
            lastName
          })
        ),

      logout: () => dispatch(logout()),

      resetPassword: () => {},

      updateProfile: () => {}
    };
  }

  //console.log(auth);
  //console.log(profile);
  //console.log('apiuser');
  //console.log(apiuser);
  //console.log(roles);
  function DoRole(roles) {
    //console.log('in do role');
    var role = '';
    if (roles) {
      var temp_roles = [];
      var r = 0;
      roles.map((role, index) => {
        if (role?.roles && !temp_roles.includes(role?.roles?.role_name)) {
          temp_roles[r++] = role.roles.role_name;
        }
      });
      role = temp_roles.join(', ');
    }
    return role;
  }

  // Firebase Auth
  return {
    method: 'firebase',
    user: {
      displayName:
        apiuser.user_firstname + ' ' + apiuser.user_lastname ||
        auth.displayName ||
        profile.displayName ||
        apiuser.user_firstname ||
        '',
      userid: apiuser.user_id || '',
      firstname: apiuser.user_firstname || '',
      lastname: apiuser.user_lastname || '',
      username: apiuser.user_username || '',
      email: auth.email || '',
      photoURL: apiuser.photoURL || auth.photoURL || profile.photoURL || '',
      phoneNumber: auth.phoneNumber || profile.phoneNumber || '',
      country: apiuser.user_country || profile.country || '',
      address: profile.address || '',
      state: apiuser.user_provstate || profile.state || '',
      city: apiuser.user_city || profile.city || '',
      zipCode: profile.zipCode || '',
      about: apiuser.user_bio || profile.about || '',
      role: DoRole(apiuser.roles) || '',
      isPublic: apiuser.user_public || false,
      isAdmin: apiuser.user_admin || false,
      isSupervisor: apiuser.user_supervisor || false,
      isScorekeeper: apiuser.user_scorekeeper || false,
      user_current_sport: apiuser.user_current_sport || '',
      user_current_profile: apiuser.user_current_profile || '',
      profiles: apiuser.profiles || [],
      roles: apiuser.roles || []
    },
    isLoading: !isLoaded(auth),
    isAuthenticated: !isEmpty(auth),
    login: ({ email, password }) =>
      firebase.login({
        email,
        password
      }),
    loginWithGoogle: () =>
      firebase.login({ provider: 'google', type: 'popup' }).then((res) => {
        //console.log('from google'), console.log(res), setLoginType('');
        // SAVE user here
        var userparams = '?uid=' + res.user.uid;
        userparams = userparams + '&user_firebase_id=' + res.user.uid;
        userparams = userparams + '&insert_if_empty=1';
        userparams = userparams + '&user_email=' + res.user.email;
        userparams = userparams + '&displayName=' + res.user.displayName;
        try {
          const response = axios.get(userUrl + userparams);
          //console.log('RESPONSE');
          //console.log(response.data);
        } catch (err) {
          //console.log(err);
        }
      }),

    loginWithFaceBook: () =>
      firebase.login({ provider: 'facebook', type: 'popup' }).then((res) => {
        //console.log('from facebook'), console.log(res), setLoginType('');
        // SAVE user here
        var userparams = '?uid=' + res.user.uid;
        userparams = userparams + '&user_firebase_id=' + res.user.uid;
        userparams = userparams + '&insert_if_empty=1';
        userparams = userparams + '&user_email=' + res.user.email;
        userparams = userparams + '&displayName=' + res.user.displayName;
        try {
          const response = axios.get(userUrl + userparams);
          //console.log('RESPONSE');
          //console.log(response.data);
        } catch (err) {
          //console.log(err);
        }
      }),

    loginWithTwitter: () =>
      firebase.login({ provider: 'twitter', type: 'popup' }),

    register: ({ email, password, firstName, lastName, province }) =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          /*
          firestore
            .collection('users')
            .doc(res.user.uid)
            .set({
              uid: res.user.uid,
              email,
              displayName: `${firstName} ${lastName}`
            }),
            //console.log(res);
          */
          // SAVE user here
          var formdata = new FormData();

          formdata.append('user_firebase_id', res.user.uid);
          formdata.append('user_email', res.user.email);
          formdata.append('user_firstname', firstName);
          formdata.append('user_lastname', lastName);
          formdata.append('user_provstate', province);

          try {
            const response = axios.post(saveUrl, formdata, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            //console.log('RESPONSE');
            //console.log(response.data);
          } catch (err) {
            //console.log(err);
          }
        }),
    logout: () => firebase.logout(),

    resetPassword: (email) => firebase.resetPassword(email),

    updateProfile: ({
      displayName,
      photoURL,
      phoneNumber,
      country,
      state,
      city,
      address,
      zipCode,
      about,
      isPublic,
      profiles,
      roles
    }) => {
      /*
      //console.log('updateProfile');
      //console.log('profiles.length');
      //console.log(profiles.length);
      //console.log('profiles.length');
      //console.log(profiles.length);
      */
      setAPIUser({ ...apiuser, ['user_public']: isPublic });
      if (profiles) {
        setAPIUser({ ...apiuser, ['profiles']: profiles });
      }
      if (roles) {
        setAPIUser({ ...apiuser, ['roles']: roles });
      }
    }
    // firebase.updateProfile({}).then((res) => {
    //   firestore.collection('users').doc(res.id).set(
    //     {
    //       displayName: displayName,
    //       photoURL: photoURL,
    //       phoneNumber: phoneNumber,
    //       country: country,
    //       state: state,
    //       city: city,
    //       address: address,
    //       zipCode: zipCode,
    //       about: about,
    //       isPublic: isPublic
    //     },
    //     { merge: true }
    //   );
    // })
  };
}
