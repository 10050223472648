import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_APP } from './paths';
// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('../views/GeneralApp'))
    },
    {
      exact: true,
      path: PATH_APP.general.ecommerce,
      component: lazy(() => import('../views/GeneralEcommerce'))
    },
    {
      exact: true,
      path: PATH_APP.general.analytics,
      component: lazy(() => import('../views/GeneralAnalytics'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      //component: () => <Redirect to={PATH_APP.general.dashboard} />
      component: () => <Redirect to={PATH_APP.members.home} />
    },

    // MANAGEMENT : E-COMMERCE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.eCommerce.products,
      component: lazy(() => import('../views/EcommerceShop'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.product,
      component: lazy(() => import('../views/EcommerceProductDetails'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.list,
      component: lazy(() => import('../views/EcommerceProductList'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.checkout,
      component: lazy(() => import('../views/EcommerceCheckout'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.invoice,
      component: lazy(() => import('../views/EcommerceInvoice'))
    },
    {
      exact: true,
      path: PATH_APP.management.eCommerce.root,
      component: () => <Redirect to={PATH_APP.management.eCommerce.products} />
    },

    // Playz
    {
      exact: true,
      path: PATH_APP.news.home,
      component: lazy(() => import('../views/News'))
    },
    {
      exact: true,
      path: PATH_APP.news.search,
      component: lazy(() => import('../views/News'))
    },
    {
      exact: true,
      path: PATH_APP.news.id,
      component: lazy(() => import('../views/News/Post'))
    },
    {
      exact: true,
      path: PATH_APP.news.newPost,
      component: lazy(() => import('../views/News/BlogNewPost'))
    },
    {
      exact: true,
      path: PATH_APP.news.edit,
      component: lazy(() => import('../views/News/BlogNewPost'))
    },
    {
      exact: true,
      path: PATH_APP.profile.home,
      component: lazy(() => import('../views/Profile'))
    },
    {
      exact: true,
      path: PATH_APP.profile.id,
      component: lazy(() => import('../views/Profile'))
    },
    {
      exact: true,
      path: '/:alias',
      component: lazy(() => import('../views/Profile'))
    },
    {
      exact: true,
      path: PATH_APP.fans.home,
      component: lazy(() => import('../views/Fans'))
    },
    {
      exact: true,
      path: PATH_APP.media.home,
      component: lazy(() => import('../views/Media'))
    },
    {
      exact: true,
      path: PATH_APP.calendar.home,
      component: lazy(() => import('../views/Calendar'))
    },
    {
      exact: true,
      path: PATH_APP.cards.new,
      component: lazy(() => import('../views/Card/Card'))
    },
    {
      exact: true,
      path: PATH_APP.cards.edit,
      component: lazy(() => import('../views/Card/Card'))
    },
    {
      exact: true,
      path: PATH_APP.cards.home,
      component: lazy(() => import('../views/Card/Cards'))
    },
    {
      exact: true,
      path: PATH_APP.teams.new,
      component: lazy(() => import('../views/Team/Team'))
    },
    {
      exact: true,
      path: PATH_APP.teams.edit,
      component: lazy(() => import('../views/Team/Team'))
    },
    {
      exact: true,
      path: PATH_APP.teams.home,
      component: lazy(() => import('../views/Team/Teams'))
    },
    {
      exact: true,
      path: PATH_APP.settings.home,
      component: lazy(() => import('../views/Settings'))
    },
    {
      exact: true,
      path: PATH_APP.settings.tab,
      component: lazy(() => import('../views/Settings'))
    },

    // MANAGEMENT : BLOG
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.blog.root,
      component: lazy(() => import('../views/BlogPosts'))
    },
    {
      exact: true,
      path: PATH_APP.management.blog.post,
      component: lazy(() => import('../views/BlogPost'))
    },
    {
      exact: true,
      path: PATH_APP.management.blog.newPost,
      component: lazy(() => import('../views/BlogNewPost'))
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.user.profile,
      component: lazy(() => import('../views/UserProfile'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.cards,
      component: lazy(() => import('../views/UserCards'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.list,
      component: lazy(() => import('../views/UserList'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.account,
      component: lazy(() => import('../views/UserAccount'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },

    // APP : CHAT
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.chat.conversation,
      component: lazy(() => import('../views/Chat'))
    },
    {
      exact: true,
      path: PATH_APP.app.chat.root,
      component: () => <Redirect to={PATH_APP.app.chat.new} />
    },

    // APP : MAIL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.mail.labels,
      component: lazy(() => import('../views/Mail'))
    },
    {
      exact: true,
      path: PATH_APP.app.mail.root,
      component: () => <Redirect to={PATH_APP.app.mail.all} />
    },

    // APP : CALENDAR
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.calendar,
      component: lazy(() => import('../views/Calendar'))
    },

    // FOUNDATIONS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.foundations.colors,
      component: lazy(() =>
        import('../views/uikit-foundations/FoundationColors')
      )
    },
    {
      exact: true,
      path: PATH_APP.foundations.typography,
      component: lazy(() =>
        import('../views/uikit-foundations/FoundationTypography')
      )
    },
    {
      exact: true,
      path: PATH_APP.foundations.shadows,
      component: lazy(() =>
        import('../views/uikit-foundations/FoundationShadows')
      )
    },
    {
      exact: true,
      path: PATH_APP.foundations.grid,
      component: lazy(() => import('../views/uikit-foundations/FoundationGrid'))
    },
    {
      exact: true,
      path: PATH_APP.foundations.icons,
      component: lazy(() => import('../views/uikit-foundations/icon'))
    },
    {
      exact: true,
      path: PATH_APP.foundations.root,
      component: () => <Redirect to={PATH_APP.foundations.colors} />
    },

    // COMPONENTS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.components.accordion,
      component: lazy(() => import('../views/uikit-components/accordion'))
    },
    {
      exact: true,
      path: PATH_APP.components.alert,
      component: lazy(() => import('../views/uikit-components/Alert'))
    },
    {
      exact: true,
      path: PATH_APP.components.autocomplete,
      component: lazy(() => import('../views/uikit-components/autocomplete'))
    },
    {
      exact: true,
      path: PATH_APP.components.avatar,
      component: lazy(() => import('../views/uikit-components/Avatar'))
    },
    {
      exact: true,
      path: PATH_APP.components.badge,
      component: lazy(() => import('../views/uikit-components/Badge'))
    },
    {
      exact: true,
      path: PATH_APP.components.breadcrumbs,
      component: lazy(() => import('../views/uikit-components/Breadcrumb'))
    },
    {
      exact: true,
      path: PATH_APP.components.buttons,
      component: lazy(() => import('../views/uikit-components/buttons'))
    },
    {
      exact: true,
      path: PATH_APP.components.chip,
      component: lazy(() => import('../views/uikit-components/chips'))
    },
    {
      exact: true,
      path: PATH_APP.components.dialog,
      component: lazy(() => import('../views/uikit-components/dialog'))
    },
    {
      exact: true,
      path: PATH_APP.components.textfield,
      component: lazy(() => import('../views/uikit-components/textfield'))
    },
    {
      exact: true,
      path: PATH_APP.components.label,
      component: lazy(() => import('../views/uikit-components/Label'))
    },
    {
      exact: true,
      path: PATH_APP.components.lists,
      component: lazy(() => import('../views/uikit-components/Lists'))
    },
    {
      exact: true,
      path: PATH_APP.components.menu,
      component: lazy(() => import('../views/uikit-components/Menus'))
    },
    {
      exact: true,
      path: PATH_APP.components.pagination,
      component: lazy(() => import('../views/uikit-components/Pagination'))
    },
    {
      exact: true,
      path: PATH_APP.components.pickers,
      component: lazy(() => import('../views/uikit-components/pickers'))
    },
    {
      exact: true,
      path: PATH_APP.components.popover,
      component: lazy(() => import('../views/uikit-components/Popover'))
    },
    {
      exact: true,
      path: PATH_APP.components.progress,
      component: lazy(() => import('../views/uikit-components/progress'))
    },
    {
      exact: true,
      path: PATH_APP.components.rating,
      component: lazy(() => import('../views/uikit-components/Rating'))
    },
    {
      exact: true,
      path: PATH_APP.components.selectionControls,
      component: lazy(() =>
        import('../views/uikit-components/selection-controls')
      )
    },
    {
      exact: true,
      path: PATH_APP.components.snackbar,
      component: lazy(() => import('../views/uikit-components/Snackbar'))
    },
    {
      exact: true,
      path: PATH_APP.components.slider,
      component: lazy(() => import('../views/uikit-components/Slider'))
    },
    {
      exact: true,
      path: PATH_APP.components.stepper,
      component: lazy(() => import('../views/uikit-components/stepper'))
    },
    {
      exact: true,
      path: PATH_APP.components.tabs,
      component: lazy(() => import('../views/uikit-components/Tabs'))
    },
    {
      exact: true,
      path: PATH_APP.components.table,
      component: lazy(() => import('../views/uikit-components/table'))
    },
    {
      exact: true,
      path: PATH_APP.components.timeline,
      component: lazy(() => import('../views/uikit-components/Timeline'))
    },
    {
      exact: true,
      path: PATH_APP.components.tooltip,
      component: lazy(() => import('../views/uikit-components/Tooltip'))
    },
    {
      exact: true,
      path: PATH_APP.components.transferList,
      component: lazy(() => import('../views/uikit-components/transfer-list'))
    },

    // EXTRA COMPONENTS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.components.chart,
      component: lazy(() => import('../views/uikit-extra-components/Charts'))
    },
    {
      exact: true,
      path: PATH_APP.components.map,
      component: lazy(() => import('../views/uikit-extra-components/Map'))
    },
    {
      exact: true,
      path: PATH_APP.components.editor,
      component: lazy(() => import('../views/uikit-extra-components/Editor'))
    },
    {
      exact: true,
      path: PATH_APP.components.copyToClipboard,
      component: lazy(() =>
        import('../views/uikit-extra-components/CopyToClipboard')
      )
    },
    {
      exact: true,
      path: PATH_APP.components.upload,
      component: lazy(() => import('../views/uikit-extra-components/Upload'))
    },
    {
      exact: true,
      path: PATH_APP.components.carousel,
      component: lazy(() => import('../views/uikit-extra-components/Carousel'))
    },
    {
      exact: true,
      path: PATH_APP.components.multiLanguage,
      component: lazy(() =>
        import('../views/uikit-extra-components/MultiLanguage')
      )
    },
    {
      exact: true,
      path: PATH_APP.components.animate,
      component: lazy(() => import('../views/uikit-extra-components/animate'))
    },
    {
      exact: true,
      path: PATH_APP.components.root,
      component: () => <Redirect to={PATH_APP.components.accordion} />
    },
    // PUBLIC
    // ----------------------------------------------------------------------
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
