// routes
import { PATH_APP, PATH_PAGE } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DescriptionIcon from '@material-ui/icons/Description';
import HelpIcon from '@material-ui/icons/Help';
import FolderIcon from '@material-ui/icons/Folder';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import StarsIcon from '@material-ui/icons/Stars';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { FormatListNumberedRtl, PlaylistAddCheck } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import peopleTeam16Filled from '@iconify-icons/fluent/people-team-16-filled';

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  map: getIcon('ic_map'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  copy: getIcon('ic_copy'),
  error: getIcon('ic_error'),
  charts: getIcon('ic_charts'),
  editor: getIcon('ic_editor'),
  upload: getIcon('ic_upload'),
  animate: getIcon('ic_animate'),
  calendar: getIcon('ic_calendar'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  language: getIcon('ic_language'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  authenticator: getIcon('ic_authenticator'),
  users: <SupervisorAccountIcon />,
  levels: <FormatListNumberedRtl />,
  modules: <PlaylistAddCheck />,
  events: <EventIcon />,
  pages: <MenuBookIcon />,
  documents: <DescriptionIcon />,
  questions: <HelpIcon />,
  categories: <FolderIcon />,
  home: <HomeIcon />,
  school: <SchoolIcon />,
  star: <StarsIcon />,
  library: <LocalLibraryIcon />,
  media: <VideoLibraryIcon />,
  settings: <SettingsIcon />,
  card: <AssignmentIndIcon />,
  team: <Icon icon={peopleTeam16Filled} width={22} height={22} />
};

const sidebarConfig = [
  // PUBLIC
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Playz',
        href: PATH_APP.news.home,
        icon: ICONS.home
      },
      {
        title: 'Profile',
        href: PATH_APP.profile.home,
        icon: ICONS.user
      },
      {
        title: 'Fans',
        href: PATH_APP.fans.home,
        icon: ICONS.users
      },
      {
        title: 'Teams',
        href: PATH_APP.teams.home,
        icon: ICONS.team
      },
      {
        title: 'Photos/Videos',
        href: PATH_APP.media.home,
        icon: ICONS.media
      },
      /*{
        title: 'Calendar',
        href: PATH_APP.calendar.home,
        icon: ICONS.calendar
      },*/
      {
        title: 'Trading Cards',
        href: PATH_APP.card.home,
        icon: ICONS.card
      },
      {
        title: 'Settings',
        href: PATH_APP.settings.home,
        icon: ICONS.settings
      }
    ]
  }
];

export default sidebarConfig;
