import { createSlice, current } from '@reduxjs/toolkit';
// utils

import axios from '../../utils/axios_playz';
//import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11,
  comments: [],
  for_thing: '',
  for_id: '',
  files: [],
  tags: [],
  post_active: true,
  post_allow_comments: true,
  currentSport: 'all',
  currentTag: '',
  currentSort: 'post_posted DESC',
  canLoad: true
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
      //console.log('getPostsSuccess');
      //console.log('state.hasMore');
      //console.log(state.hasMore);
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = [...state.posts, ...action.payload];
      //state.posts = action.payload;
      state.num_loaded = state.posts.length;
      //console.log('getPostsInitial');
      //console.log('state.hasMore');
      //console.log(state.hasMore);
      //console.log('state.num_loaded');
      //console.log(state.num_loaded);
      state.canLoad = false;
    },

    getMorePosts(state) {
      //console.log('getting more posts');
      const setIndex = state.index + state.step;
      state.index = setIndex;
      state.canLoad = true;
    },

    noHasMore(state) {
      state.hasMore = false;
      //console.log('setting hasMore to false');
    },

    resetPosts(state) {
      state.hasMore = true;
      state.canLoad = true;
      state.index = 0;
      state.posts = [];
      state.currentSport = 'all';
      state.currentTag = '';
      state.currentSort = 'post_posted DESC';
      //console.log(initialState);
      //state = initialState;
      //console.log('resetting posts');
    },
    setCurrentSport(state, action) {
      //console.log('setting sport');
      //console.log(action.payload);
      state.currentSport = action.payload;
    },
    setCurrentTag(state, action) {
      //console.log('setting tag');
      //console.log(action.payload);
      state.currentTag = action.payload;
    },
    setCurrentSort(state, action) {
      //console.log('setting sort');
      //console.log(action.payload);
      state.currentSort = action.payload;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    },

    // GET COMMENTS
    getCommentsSuccess(state, action) {
      state.isLoading = false;
      const comments = action.payload;
      //console.log(comments);
      state.comments = comments;
    },

    // ON SEND MESSAGE
    onSendComment(state, action) {
      const comment = action.payload;
      const {
        id,
        name,
        comment_user,
        username,
        avatarUrl,
        postedAt,
        replyComment,
        message
      } = comment;

      const newComment = {
        id: id,
        name: name,
        comment_user: comment_user,
        username: username,
        avatarUrl: avatarUrl,
        postedAt: postedAt,
        replyComment: replyComment,
        message: message
      };

      state.comments.push(newComment);
    },

    // ON SEND REPLY
    onSendReply(state, action) {
      const reply = action.payload;
      const {
        id,
        parent,
        comment_user,
        username,
        name,
        avatarUrl,
        postedAt,
        message
      } = reply;

      const newReply = {
        id: id,
        parent: parent,
        comment_user: comment_user,
        username: username,
        name: name,
        avatarUrl: avatarUrl,
        postedAt: postedAt,
        message: message
      };
      //console.log(current(state));
      var thiskey = -1;
      for (const [key, value] of Object.entries(state.comments)) {
        if (value.id == parent) {
          thiskey = key;
          break;
        }
      }
      if (thiskey >= 0) {
        state.comments[thiskey].replyComment.push(newReply);
      }
    },
    // GET TAGS
    getTagsSuccess(state, action) {
      state.isLoading = false;
      state.tags = action.payload;
    },

    // ON SAVE LIKE
    saveLikeSuccess(state, action) {
      const like = action.payload;
      const likes = Number(state.post.likes) + 1;
      state.post.likes = likes;
      state.post.post_liked = 1;
    },
    deleteLikeSuccess(state, action) {
      const like = action.payload;
      const likes = Number(state.post.likes) - 1;
      state.post.likes = likes;
      state.post.post_liked = 0;
    },
    // DELETE POST
    deletePostuccess(state, action) {
      const { postId } = action.payload;
      const deletePost = reject(state.posts, { id: postId });

      state.isLoading = false;
      state.posts = deletePost;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getMorePosts,
  onSendComment,
  onSendReply,
  startLoading,
  hasError
} = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts(sport) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/posts', {
        params: { sport }
      });
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPostsInitial(viewer, index, step, sport, tag, sort) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/posts', {
        params: { viewer, index, step, sport, tag, sort }
      });
      //console.log('response.data');
      //console.log(response.data);
      const results = response.data.results.length;
      const total_loaded = index + results;
      const { maxLength } = response.data;
      //console.log('index:' + index);
      //console.log('results:' + results);
      //console.log('total_loaded:' + total_loaded);
      //console.log('maxLength:' + maxLength);
      dispatch(slice.actions.getPostsInitial(response.data.results));

      if (total_loaded >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      //console.log('post initial error');
      //console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(title, userid = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/post', {
        params: { title, userid }
      });
      dispatch(slice.actions.getPostSuccess(response.data));
    } catch (error) {
      //console.log('post error');
      console.error(error);
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentPosts(viewer, title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/posts', {
        params: { viewer, title }
      });

      dispatch(slice.actions.getRecentPostsSuccess(response.data.recentPosts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export function getComments(for_thing, for_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/comments', {
        params: { for_thing, for_id }
      });
      dispatch(slice.actions.getCommentsSuccess(response.data));
    } catch (error) {
      //console.log('comment error');
      //console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTags(sportid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/tags', {
        params: { sportid }
      });
      dispatch(slice.actions.getTagsSuccess(response.data));
    } catch (error) {
      //console.log('tags error');
      //console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveLike(user, post, val) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    formdata.append('like_type', 'post');
    formdata.append('like_id', post);
    formdata.append('like_user', user);
    formdata.append('save', val);
    try {
      const response = await axios.post('/save/like', formdata);
      //console.log(response.data);
      if (val == 'delete') {
        dispatch(slice.actions.deleteLikeSuccess(response.data, val));
      } else {
        dispatch(slice.actions.saveLikeSuccess(response.data, val));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.resetPosts());
  };
}
export function setCurrentSport(sport) {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentSport(sport));
  };
}
export function setCurrentTag(tag) {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentTag(tag));
  };
}
export function setCurrentSort(sort) {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentSort(sort));
  };
}

export function deletePost(postId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    formdata.append('id', postId);
    try {
      console.log('deleting ' + postId);
      const response = await axios.post('/delete/post', formdata);
      console.log('response ' + response.data);
      dispatch(slice.actions.deletePostSuccess({ postId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
