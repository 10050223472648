import { map, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
//import axios from '../../utils/axios';
import axios from '../../utils/axios_playz';
import { fDateTimeMySQL } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = reject(state.events, { id: eventId });

      state.isLoading = false;
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/events', {
        params: { user }
      });
      //console.log(response.data);
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(user, newEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    // title, description, textColor, allDay, end, start
    //console.log('newEvent');
    //console.log(newEvent);
    formdata.append('event_name', newEvent.title);
    formdata.append('event_description', newEvent.description);
    formdata.append('event_text_color', newEvent.textColor);
    formdata.append('event_all_day', newEvent.allDay);
    formdata.append('date_start', fDateTimeMySQL(newEvent.start));
    formdata.append('date_end', fDateTimeMySQL(newEvent.end));
    formdata.append('event_user', user);
    try {
      const response = await axios.post('/save/event', formdata);
      newEvent.id = response.data;
      dispatch(slice.actions.createEventSuccess(newEvent));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    // title, description, textColor, allDay, end, start
    //console.log('updateEvent');
    //console.log(updateEvent);
    formdata.append('event_id', eventId);
    formdata.append('event_name', updateEvent.title);
    formdata.append('event_description', updateEvent.description);
    formdata.append('event_text_color', updateEvent.textColor);
    formdata.append('event_all_day', updateEvent.allDay);
    formdata.append('date_start', fDateTimeMySQL(updateEvent.start));
    formdata.append('date_end', fDateTimeMySQL(updateEvent.end));
    try {
      const response = await axios.post('/save/event', formdata);
      updateEvent.id = eventId;
      dispatch(slice.actions.updateEventSuccess(updateEvent));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    formdata.append('id', eventId);
    try {
      //console.log('deleting ' + eventId);
      const response = await axios.post('/delete/event', formdata);
      //console.log('response ' + response.data);
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}
