import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------
function isValid(date) {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  return date.getTime() === date.getTime();
}
export function fDate(date) {
  const newdate = new Date(date);
  if (!isValid(newdate)) {
    return false;
  }
  return format(newdate, 'dd MMMM yyyy');
}

export function fDateTime(date) {
  const newdate = new Date(date);
  if (!isValid(newdate)) {
    return false;
  }
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  const newdate = new Date(date);
  if (!isValid(newdate)) {
    return false;
  }
  return format(newdate, 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  const newdate = new Date(date);
  if (!isValid(newdate)) {
    return false;
  }
  return formatDistanceToNow(newdate, {
    addSuffix: true
  });
}

export function fDateTimeMySQL(date) {
  const newdate = new Date(date);
  if (!isValid(newdate)) {
    return false;
  }
  return format(newdate, 'yyyy-MM-dd  HH:mm:ss');
}
