import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios_playz';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSaving: false,
  error: false,
  myProfile: {},
  posts: [],
  users: [],
  userList: [],
  followers: [],
  followings: [],
  friends: [],
  gallery: [],
  profiles: [],
  roles: [],
  cards: [],
  card: {},
  cardNewId: '',
  addressBook: [],
  invoices: [],
  notifications: null,
  allRoles: [],
  isFollowed: false,
  aliasType: '',
  aliasId: '',
  groups: [],
  group: {},
  groupNewId: ''
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startSaving(state) {
      state.isSaving = true;
    },

    startLoadingPosts(state) {
      state.isLoadingPosts = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
      //console.log('setting profile in user.js');
    },
    // GET ALIAS
    getAliasSuccess(state, action) {
      state.isLoading = false;
      var tempThing = action.payload;
      var tempArray = tempThing.split('|');
      state.aliasType = tempArray[0];
      state.aliasId = tempArray[1];
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.posts = action.payload;
      state.isLoading = false;
      state.isLoadingPosts = false;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET ALL ROLES
    getAllRolesSuccess(state, action) {
      state.isLoading = false;
      state.allRoles = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // GET FOLLOWING
    getFollowingsSuccess(state, action) {
      state.isLoading = false;
      state.followings = action.payload;
    },
    // GET FOLLOWED
    getFollowedSuccess(state, action) {
      state.isLoading = false;
      var tempfollowed = action.payload;
      //console.log('tempfollowed');
      //console.log(tempfollowed);
      state.isFollowed = tempfollowed.length > 0;
    },
    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;
      //console.log('state.followers before');
      //console.log(state.followers);
      //console.log('followerId');
      //console.log(followerId);
      const handleToggle = map(state.followers, (follower) => {
        //console.log('follower.id');
        //console.log(follower.id);
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });
      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
      state.cardNewId = '';
    },
    getCardSuccess(state, action) {
      state.isLoading = false;
      state.card = action.payload;
      //state.cardNewId = '';
    },
    saveCardSuccess(state, action) {
      state.isSaving = false;
      console.log('setting state.cardNewId', action.payload);
      state.cardNewId = action.payload;
      state.card.card_id = action.payload;
    },

    // GET GROUPS
    getGroupsSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
      state.groupNewId = '';
    },
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.group = action.payload;
      //state.groupNewId = '';
    },
    saveGroupSuccess(state, action) {
      state.isSaving = false;
      console.log('setting state.groupNewId', action.payload);
      state.groupNewId = action.payload;
      //state.group.group_id = action.payload;
    },
    saveGroupUsersSuccess(state, action) {
      state.isSaving = false;
      state.groupNewId = action.payload;
      //state.group.group_id = action.payload;
    },
    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    resetMyProfile(state) {
      state.myProfile = {};
    },
    resetCard(state) {
      state.card = {};
    },
    saveUserLinksSuccess(state, action) {
      state.isSaving = false;
      //state.card.card_id = card_id;
      //getCard(action.payload);
    },
    onToggleAcceptGroup(state, action) {
      state.isSaving = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile(id, profile = '', userid = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log('try getting profile ' + id);
    try {
      if (id) {
        const response = await axios.get('/get/user_details', {
          params: { id, profile, userid }
        });
        //console.log('response.data getting profile');
        //console.log(response.data);
        //response.data.cover = mockImgCover(1);
        //response.data.company = faker.company.companyName();
        //response.data.school = faker.company.companyName();
        dispatch(slice.actions.getProfileSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts(viewer, user_id, profile_id = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingPosts());
    try {
      const response = await axios.get('/get/posts', {
        params: { viewer, user_id, profile_id }
      });
      //console.log(response.data.results);
      dispatch(slice.actions.getPostsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers(user_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/fans', {
        params: { type: 'id', id: user_id } // id for followers, user for following
      });
      //console.log(response.data);
      dispatch(slice.actions.getFollowersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowings(user_id, of_user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/fans', {
        // id for followers, user for following
        params: { type: 'user', id: user_id, of_user: of_user }
      });
      //console.log(response.data);
      if (of_user) {
        dispatch(slice.actions.getFollowedSuccess(response.data));
      } else {
        dispatch(slice.actions.getFollowingsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends(user_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/friends', {
        params: { user_id }
      });
      dispatch(slice.actions.getFriendsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery(viewer, user_id, profile_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/medias', {
        params: {
          viewer: viewer,
          for_thing: 'usermedia',
          for_id: user_id,
          profile_id: profile_id
        }
      });
      dispatch(slice.actions.getGallerySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/users');
      dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      //const response = await axios.get('/get/settings');
      //dispatch(slice.actions.getNotificationsSuccess(response.data));
      const notifications = {
        activityComments: true,
        activityAnswers: true,
        activityFollows: false,
        applicationNews: true,
        applicationProduct: false,
        applicationBlog: false
      };
      dispatch(slice.actions.getNotificationsSuccess(notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/users');
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllRoles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/roles');
      //console.log(response.data);
      dispatch(slice.actions.getAllRolesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function toggleFan(user, id, save) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    formdata.append('fan_user', user);
    formdata.append('fan_type', 'user');
    formdata.append('fan_id', id);
    var doing = 'save';
    if (save == 'delete') {
      doing = 'delete';
    }
    //console.log(doing);
    //console.log(user);
    //console.log(id);
    try {
      const response = await axios.post('/' + doing + '/fan', formdata);
      //console.log(response.data);
      dispatch(slice.actions.onToggleFollow(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetMyProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.resetMyProfile());
  };
}

// ----------------------------------------------------------------------

export function getCards(user_id, profile_id = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/cards', {
        params: {
          user_id: user_id,
          profile_id: profile_id
        }
      });
      console.log(response.data);
      dispatch(slice.actions.getCardsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCard(card_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/card', {
        params: {
          id: card_id
        }
      });
      console.log('get/card response', response);
      dispatch(slice.actions.getCardSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveCard(cardStuff, setDoGet = {}) {
  return async (dispatch) => {
    console.log('cardStuff', cardStuff);
    dispatch(slice.actions.startSaving());
    var formdata = new FormData();
    formdata.append('card_id', cardStuff.card_id);
    formdata.append('card_user', cardStuff.card_user);
    //formdata.append('card_profile', cardStuff.card_profile);
    //formdata.append('card_sport', cardStuff.card_sport);
    formdata.append('card_name', cardStuff.card_name);
    formdata.append('card_team', cardStuff.card_team);
    formdata.append('card_number', cardStuff.card_number);
    formdata.append('card_color', cardStuff.card_color);
    formdata.append('card_font', cardStuff.card_font);
    formdata.append('card_template', cardStuff.card_template);
    formdata.append('card_image_x', cardStuff.card_image_x);
    formdata.append('card_image_y', cardStuff.card_image_y);
    formdata.append('card_image_size', cardStuff.card_image_size);
    formdata.append('photo_file', cardStuff.photo_file);
    formdata.append('logo_file', cardStuff.logo_file);

    console.log('formdata', formdata);
    try {
      const response = await axios.post('/save/card', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      console.log('response.data', response.data);
      dispatch(slice.actions.saveCardSuccess(response.data));
      setDoGet(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetCard() {
  return async (dispatch) => {
    dispatch(slice.actions.resetCard());
  };
}

export function getAlias(alias) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/alias', {
        params: { alias }
      });
      dispatch(slice.actions.getAliasSuccess(response.data));
    } catch (error) {
      //console.log('post error');
      console.error(error);
      dispatch(slice.actions.hasError());
    }
  };
}

export function saveUserLinks(formdata, setDoGet = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving());
    try {
      const response = await axios.post('/save/user', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      console.log('response.data', response.data);
      dispatch(slice.actions.saveUserLinksSuccess(response.data));
      setDoGet(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGroups(user_id, profile_id = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/groups', {
        params: {
          user_id: user_id,
          profile_id: profile_id
        }
      });
      console.log(response.data);
      dispatch(slice.actions.getGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGroup(group_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/group', {
        params: {
          id: group_id
        }
      });
      console.log('get/group response', response);
      dispatch(slice.actions.getGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveGroup(formdata, setDoGet = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving());
    console.log('formdata', formdata);
    try {
      const response = await axios.post('/save/group', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      console.log('response.data', response.data);
      dispatch(slice.actions.saveGroupSuccess(response.data));
      setDoGet(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function saveGroupUsers(formdata, setDoGet = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving());
    console.log('formdata', formdata);
    try {
      const response = await axios.post('/save/group_users', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      console.log('response.data', response.data);
      dispatch(slice.actions.saveGroupUsersSuccess(response.data));
      setDoGet(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function toggleAcceptGroup(user, group, who, save) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving());
    var formdata = new FormData();
    formdata.append('gu_user', user);
    formdata.append('gu_group', group);
    formdata.append('who', who); // creator or user
    var doing = 'save';
    if (save == 'delete') {
      doing = 'delete';
    }
    console.log('user', user);
    console.log('group', group);
    console.log('who', who);
    console.log('save', save);
    try {
      const response = await axios.post('/' + doing + '/group_user', formdata);
      console.log('response.data', response.data);
      dispatch(slice.actions.onToggleAcceptGroup(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
